import React from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  Export,
  FilterRow
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import {empleados} from '../../api/crm'
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import CustomStore from 'devextreme/data/custom_store';


export default function Task() {
  
  return (
    <React.Fragment>
      
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onExporting = {onExporting}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        
        <Export enabled={true}  allowExportSelectedData={true} />
        
        
        <Column
          dataField={'codigo'}
          caption={'Código'}
          hidingPriority={3}
        />
        <Column
          dataField={'nombre'}
          caption={'Nombre'}
          hidingPriority={3}
        />
        <Column
          dataField={'apellidos'}
          caption={'Apellidos'}
          hidingPriority={3}
        />

<Column
          dataField={'phone'}
          caption={'Teléfono'}
          hidingPriority={3}
        />

<Column
          dataField={'email'}
          caption={'Email'}
          hidingPriority={3}
        />
<Column
          dataField={'region'}
          caption={'Region'}
          hidingPriority={3}
        />
        <Column
          dataField={'estado'}
          caption={'Estado'}
          hidingPriority={3}
        />

<Column
          dataField={'cp'}
          caption={'CP'}
          hidingPriority={3}
        />
        
        <Column
          dataField={'SYS_CREATE_AT'}
          caption={'Fecha Inicial'}
          dataType={'date'}
          hidingPriority={3}
        />
        <Column
          dataField={'SYS_UPDATE_AT'}
          caption={'Actualizo'}
          dataType={'date'}
          hidingPriority={4}
        />
        <Column dataField="url_ine_delantera"
          width={50}
          caption={'INE'}  
          allowSorting={false}
          cellRender={cellRender}
        />
        <Column dataField="url_ine_trasera"
        caption={'INE'}
          width={100}
          allowSorting={false}
          cellRender={cellRender}
        />

<Column dataField="url_curp"
          width={50}
          caption={'CURP'}  
          allowSorting={false}
          cellRender={cellRender}
        />
        <Column dataField="url_constanciafiscal"
        caption={'RFC'}
          width={100}
          allowSorting={false}
          cellRender={cellRender}
        />

<Column dataField="url_comprobante"
        caption={'Comprobante'}
          width={100}
          allowSorting={false}
          cellRender={cellRender}
        />

      </DataGrid>
    </React.Fragment>
)}

const onExporting =  (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    });
  });
  e.cancel = true;
}; 

function cellRender(data) {
  return <img src={'/images/' +data.value} height="50px" />;
}

const dataSource = new CustomStore({
    key: 'empleado_id',
    load : () => empleados()
  });

