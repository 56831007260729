export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Profile',
    path: '/profile',
    icon: 'user'
  },
  /*{
    text: 'Sistema',
    icon: 'folder',
    items: [
      {
        text: 'Usuarios',
        path: '/tasks'
      },
      {
        text: 'Roles',
        path: '/users'
      }
    ]
  },*/
  /*{
    text: 'Productos',
    icon: 'folder',
    items: [
      {
        text: 'Marcas',
        path: '/marcas'
      },
      {
        text: 'Modelos',
        path: '/modelos'
      },
      {
        text: 'Prooductos',
        path: '/productos'
      }
    ]
  },*/
  {
    text: 'Empleados',
    icon: 'folder',
    items: [
      {
        text: 'Empleados',
        path: '/empleados'
      },
      {
        text: 'Asistencia',
        path: '/asistencia'
      }/*,
      {
        text: 'comisiones',
        path: '/comisiones'
      }*/
    ]
  },
  /*{
    text: 'Clientes',
    icon: 'folder',
    items: [
      {
        text: 'clientes',
        path: '/clientes'
      }
    ]
  }*/
  ];
