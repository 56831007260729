import React from 'react';
import 'devextreme/data/odata/store';
import { Button ,DateBox} from 'devextreme-react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  Export,
  SearchPanel,
  GroupPanel,
  
  Toolbar,
  
  Item
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import {asistencia} from '../../api/crm'
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import CustomStore from 'devextreme/data/custom_store';


export default function Task() {
  
  return (
    <React.Fragment>
      
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={dataSource}
        
        showBorders={true}
        groupIndex={0} 
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onExporting = {onExporting}
        ref={ ref => dataGrid = ref}

      >
        
        <Paging defaultPageSize={100} />
        <Pager showPageSizeSelector={true} showInfo={false} />
        <SearchPanel visible={true}></SearchPanel>
        <Export enabled={true}  allowExportSelectedData={true} />
        
        
        <Column
          calculateCellValue = { master}
          caption={'Empleado'}
          hidingPriority={3}
          groupIndex={0}
        />

      <Column
          caption={'Tipo'}
          calculateCellValue = { tipoValue}
          
        />
      
      <Column
          caption={'Hora de registro'}
          calculateCellValue = { hour}
          hidingPriority={3}
        />          

        <Column
          dataField={'location'}
          caption={'Coordenadas'}
          hidingPriority={3}
        />
        
        <Column
          dataField={'info'}
          caption={'Dispositivo'}
          hidingPriority={3}
        />
      
        <Column
          dataField={'device_date'}
          caption={'Fecha telefono'}
          dataType={'date'}
          />

        <Column
          dataField={'server_time'}
          caption={'Fecha servidor'}
          dataType={'time'}
          hidingPriority={3}
        />

      <Toolbar>
        <Item location="after">
            <DateBox
                type="date"
                placeholder="04/04/2022"
                showClearButton={true}
                useMaskBehavior={true} 
                stylingMode='outlined'
                label='fecha Inicial'
                labelMode='static'
                onValueChanged={onValueDateIniChanged}
                />
        </Item>
        <Item location="after">
            <DateBox
                type="date"
                placeholder="04/04/2022"
                showClearButton={true}
                useMaskBehavior={true} 
                stylingMode='outlined'
                label='fecha final'
                labelMode='static'
                onValueChanged={onValueDateEndChanged}
                />
        </Item>
        <Item location="after">
            <Button
                text='Aplicar filtro'
                onClick={onValueDateChanged}
                />
        </Item>
      </Toolbar>
        
      </DataGrid>
    </React.Fragment>
)}

var dataGrid = undefined;
const onExporting =  (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    });
  });
  e.cancel = true;
}; 

var date_ini = new Date("04/03/2023");
var date_fin = new Date();

const onValueDateIniChanged = (e) => {
  date_ini = e.value;
};
const onValueDateEndChanged = (e) => {
  date_fin = e.value;
};

const onValueDateChanged = (e) => {
  dataSource.load();
  dataGrid.instance.refresh();
};


var dataSource = new CustomStore({
    key: 'time_clock_id',
    load : () => asistencia(date_ini,date_fin)
  });

  const master = function(rowdata) {
    return rowdata.empleado_id + " " +
    rowdata.nombre + " " +
    rowdata.apellidos;
    
};

const hour = function(rowdata) {
   return new Date(rowdata.device_time).toLocaleTimeString('es-MX');
};

  const tipoValue = function(rowdata) {
    let type = parseInt(rowdata.type);
    if (type ===  0) return "Inicio labores";
    else if (type ===  1) return "Fin de labores";
    else if (type ===  2) return "Inicio comida";
    else if (type ===  3) return "fin comida";
    else if (type ===  4) return "Incidencia inicio labores";
    else if (type ===  5) return "Incidencia fin labores";
    else return type;
};

