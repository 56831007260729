import React from 'react';
import './home.scss';

export default function Home() {
  const logo = "https://timeclockapp.eirsmx.com/images/uploads/20230620/timeclock_logo.png";
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Bienvenido a Reloj Checador</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
         
        <img src={logo} style={{ width: 250, height: 120 }} alt="Logo" />
          
          <p>
            <span>Administración de empleados </span>
            
          </p>
             <ul>
            <li><a href={'/empleados'} target={'_blank'} rel={'noopener noreferrer'}>Empleados</a></li>
            <li><a href={'/asistencia'} target={'_blank'} rel={'noopener noreferrer'}>Asistencia</a></li>
            
          </ul>
        </div>
      </div>
    </React.Fragment>
)}
