import React from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  Popup,
  Export,
  Editing,
  
  RequiredRule,
  Form
  
  
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import {clientes, PostData, PutData} from '../../api/crm'
import { Item } from 'devextreme-react/form';
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import CustomStore from 'devextreme/data/custom_store';


export default function Task() {
  
  return (
    <React.Fragment>
      
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onExporting = {onExporting}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        
        <Export enabled={true}  allowExportSelectedData={true} />
        <Editing
            mode="popup"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={false}>
               <Popup title="Modelos" showTitle={true} width={720} height={625} />
              <Form>
              
                <Item dataField="nombre"  colSpan={1} >
                <RequiredRule />
               </Item>
               
               <Item dataField="apellidos" colSpan={1}>
                <RequiredRule />
                </Item>
                 <Item dataField="phone" colSpan={2}>
                  <RequiredRule />
              </Item>


              <Item dataField="email" colSpan={2}>
                  <RequiredRule />
              </Item>
              <Item dataField="rfc" colSpan={2}>
                  <RequiredRule />
              </Item>

              <Item dataField="region" colSpan={2}>
                  <RequiredRule />
              </Item>

              <Item dataField="calle" colSpan={1}>
                  <RequiredRule />
              </Item>


              <Item dataField="colonia" colSpan={1}>
                  <RequiredRule />
              </Item>
                

              <Item dataField="estado" colSpan={1}>
                  <RequiredRule />
              </Item>

              <Item dataField="municipio" colSpan={1}>
                  <RequiredRule />
              </Item>

              <Item dataField="cp" colSpan={1}>
                  <RequiredRule />
              </Item>

              
              </Form>
        </Editing>

        <Column
          dataField={'codigo'}
          caption={'codigo'}
          hidingPriority={3}
        >
            
          </Column>        

        <Column
          dataField={'nombre'}
          caption={'nombre'}
          hidingPriority={3}
        />
                <Column
          dataField={'apellidos'}
          caption={'apellidos'}
          hidingPriority={3}
        />
        <Column
          dataField={'phone'}
          caption={'Teléfono'}
          hidingPriority={3}
        />


<Column
          dataField={'email'}
          caption={'Email'}
          hidingPriority={3}
        />
<Column
          dataField={'region'}
          caption={'Region'}
          hidingPriority={3}
        />
<Column
          dataField={'calle'}
          caption={'Calle'}
          hidingPriority={3}
        />
        <Column
          dataField={'colonia'}
          caption={'colonia'}
          hidingPriority={3}
        />
        <Column
          dataField={'estado'}
          caption={'Estado'}
          hidingPriority={3}
        />
        <Column
          dataField={'municipio'}
          caption={'municipio'}
          hidingPriority={3}
        />
        <Column
          dataField={'cp'}
          caption={'CP'}
          hidingPriority={3}
        />

<Column
          dataField={'rfc'}
          caption={'RFC'}
          hidingPriority={3}
        />
       


      </DataGrid>
    </React.Fragment>





)}





const onExporting =  (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'modelos.xlsx');
    });
  });
  e.cancel = true;
}; 


const insertCliente = (values) => {
  console.log(values);
  PostData("cliente",values);
  
};

const updateCliente = (key,values) => {
  console.log(values);
  PutData(`cliente/${key}`,values);
};

const dataSource = new CustomStore({
    key: 'cliente_id',
    load : () => clientes(),
    update : updateCliente,
    insert : insertCliente,
  });

;
