
import axios from 'axios';
import { createUrl, getConfigAuth, services } from './session'


function islogin() { 
    var token = window.sessionStorage.getItem("token_session")
    return  token !== undefined && token.length > 0;
}

export async function empleados() {
    try {
      
      if (!islogin()) return { isOk : false}
      
      
      return axios.get(createUrl(services.empleados),getConfigAuth())
                  .then((data) => {
                      return {
                        isOk: true,
                        data: data.data
                      }
                  }).catch( err => {
                      var response = err.response;
                      return {
                        isOk: false,
                        message: "Usuario no encontrado: " + response.data.error
                      }  
                  });
    } catch {
      return {
        isOk: false
      };
    }
  }

export async function marcas() {
  return GetData(services.marcas);
}

export async function modelos() {
  return GetData(services.modelos);
}

export async function clientes() {
  return GetData(services.clientes);
}


export async function productos() {
  return GetData(services.productos);
}

async function GetData(endpoint) {
    try {
      
      if (!islogin()) return { isOk : false}
      
      return axios.get(createUrl(endpoint),getConfigAuth())
                  .then((data) => {
                      return {
                        isOk: true,
                        data: data.data.data
                      }
                  }).catch( err => {
                      var response = err.response;
                      return {
                        isOk: false,
                        message: "no se encontro acceso: " + response.data.error
                      }  
                  });
    } catch {
      return {
        isOk: false
      };
    }
  }

  export async function PostData(endpoint,data) {
    try {
      
      if (!islogin()) return { isOk : false}
      
      return axios.post(createUrl(endpoint),data,getConfigAuth())
                  .then((data) => {
                      return {
                        isOk: true,
                        data: data.data.data
                      }
                  }).catch( err => {
                      var response = err.response;
                      return {
                        isOk: false,
                        message: "no se encontro acceso: " + response.data.error
                      }  
                  });
    } catch {
      return {
        isOk: false
      };
    }
  }

  export async function PutData(endpoint,data) {
    try {
      
      if (!islogin()) return { isOk : false}
      
      return axios.put(createUrl(endpoint),data,getConfigAuth())
                  .then((data) => {
                      return {
                        isOk: true,
                        data: data.data.data
                      }
                  }).catch( err => {
                      var response = err.response;
                      return {
                        isOk: false,
                        message: "no se encontro acceso: " + response.data.error
                      }  
                  });
    } catch {
      return {
        isOk: false
      };
    }
  }

  export async function asistencia(date_ini = undefined,date_fin = undefined) {
    try {
      
      if (!islogin()) return { isOk : false}
    
      var mainUrl = createUrl(services.asistencia);
      
      if (date_ini === undefined){
        date_ini = new Date();
      }
      
      mainUrl += "/" + ("0"+(date_ini.getDate())).slice(-2) 
              +  "-" + ("0"+(date_ini.getMonth()+1)).slice(-2) 
              +  "-" + date_ini.getFullYear();

      if (date_fin !== undefined && date_fin !== date_ini) {
        mainUrl += "/" + ("0"+(date_fin.getDate())).slice(-2) 
              +  "-" + ("0"+(date_fin.getMonth()+1)).slice(-2) 
              +  "-" + date_fin.getFullYear();

      }

      return axios.get(mainUrl,getConfigAuth())
                  .then((data) => {
                      return  data.data
                     
                  }).catch( err => {
                      var response = err.response;
                      return {
                        isOk: false,
                        message: "Authentication failed: " + response.data.error
                      }  
                  });
    } catch {
      return {
        isOk: false
      };
    }
  }