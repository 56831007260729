import defualt_config_session from '../utils/defualt_config_session';

export const services =  {
    login : 'login',
    user : 'profile',
    roles : 'roles',
    empleados : 'empleados',
    asistencia : 'asistencia',
    productos : 'producto',
    marcas : 'marca',
    modelos : 'modelo',
    clientes : 'cliente',
    ventas : 'ventas',
}

export function createUrl(endpoint = "") {
    return  defualt_config_session.url + endpoint;
}


export function getConfigAuth() {
    var token = window.sessionStorage.getItem("token_session")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return config;
  }