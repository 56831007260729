
import axios from 'axios';
import { createUrl, getConfigAuth, services } from './session'

function islogin() { 
    var token = window.sessionStorage.getItem("token_session")
    return  token !== undefined && token.length > 0;
}

export async function getUser() {
    try {
      
      if (!islogin()) return { isOk : false}
      
      
      return axios.get(createUrl(services.user),getConfigAuth())
                  .then((data) => {
                      return {
                        isOk: true,
                        data: data.data.profile
                      }
                  }).catch( err => {
                      var response = err.response;
                      return {
                        isOk: false,
                        message: "Usuario no encontrado: " + response.data.error
                      }  
                  });
    } catch {
      return {
        isOk: false
      };
    }
  }

  export async function getRoles() {
    try {
      
      if (!islogin()) return { isOk : false}
    
      return axios.get(createUrl(services.roles),getConfigAuth())
                  .then((data) => {
                      return  data.data
                     
                  }).catch( err => {
                      var response = err.response;
                      return {
                        isOk: false,
                        message: "Authentication failed: " + response.data.error
                      }  
                  });
    } catch {
      return {
        isOk: false
      };
    }
  }