import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './single-card.scss';


export default function SingleCard({ title, description, children }) {
  const logo = "https://timeclockapp.eirsmx.com/images/uploads/20230620/timeclock_logo.png";
  return (
    <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card'}>
      <div className={'dx-card content'}>
        <div className={'header'}>
          
          
          <img src={logo} style={{ width: 250, height: 180 }} alt="Logo" />
          <div className={'title'}>{title}</div>
          <div className={'description'}>{description}</div>
        </div>
        {children}
      </div>
    </ScrollView>
)}
