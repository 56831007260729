import React , { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  Export,
  Editing,
  Lookup,
  RequiredRule,
  Form,
  Popup,
  
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

import {productos,modelos,marcas, PostData, PutData} from '../../api/crm'
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import CustomStore from 'devextreme/data/custom_store';



export default function Task() {
  

  const insertMarcas = (values) => {
    PostData("producto",values);
  };
  
  const updateMarcas = (key,values) => {
    PutData(`producto/${key}`,values);
  };
  
  const getFilteredModels = (options) => {
    var filtro = options.data ? ["codigo_marca", '=', "SAMSUMG"] : null;
    
    console.log(filtro);
    return {
      store: dataSourceModelos,
      filter: filtro,
    };
  };

  const dataSource = new CustomStore({
      key: 'producto_id',
      load : () => {
        
        return productos();
      },
      update : updateMarcas,
      insert : insertMarcas,
    });

    function onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'modelo') {
        e.editorOptions.disabled = (typeof e.row.data.marca !== 'string');
      }
    }

    function setStateValue  (rowData, value) {
      rowData.modelo = null;
      this.defaultSetCellValue(rowData, value);
      return;
    }

  return (
    <React.Fragment>
      
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onExporting = {onExporting}
        onEditorPreparing={onEditorPreparing}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        
        <Export enabled={true}  allowExportSelectedData={true} />
        <Editing
            mode="popup"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={false}>
               <Popup title="Modelos" showTitle={true} width={620} height={525} />
              <Form>
              
              <Item dataField="descripcion" colSpan={2}>
                  <RequiredRule />
              </Item>
              <Item dataField="descripcion_corta" colSpan={2}>
                  <RequiredRule />
              </Item>

              <Item dataField="marca" >
                  <RequiredRule />
              </Item>


              <Item dataField="modelo" >
                  <RequiredRule />
              </Item>

              <Item dataField="unidad" colSpan={2}>
                  <RequiredRule />
              </Item>
              </Form>
        </Editing>
        

        <Column
          dataField={'producto_id'}
          caption={'id'}
          hidingPriority={3}
        />
        <Column
          dataField={'descripcion'}
          caption={'Descripción'}
          hidingPriority={3}
        />
        <Column
          dataField={'descripcion_corta'}
          caption={'Descripción Corta'}
          hidingPriority={3}
        />

        <Column
          dataField={'marca'}
          caption={'Marca'}
          
          setCellValue={setStateValue}
        >
          <Lookup dataSource={dataSourceMarca} valueExpr="codigo" displayExpr="codigo" />
          </Column>

        <Column
          dataField={'modelo'}
          caption={'Modelo'}
          hidingPriority={3}
        >
          <Lookup dataSource={getFilteredModels} valueExpr="codigo" displayExpr="descripcion" />
          </Column>
      <Column
          dataField={'unidad'}
          caption={'Unidad'}
          hidingPriority={3}
        >
          <Lookup dataSource={dataSourceUnidad} valueExpr="codigo" displayExpr="descripcion" />
        </Column>


      </DataGrid>
    </React.Fragment>
)}


const dataSourceUnidad = [
  { codigo : 'UN', descripcion : "UNIDAD"},
  { codigo : 'PZA', descripcion : "PIEZA"}
];

const onExporting =  (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Productos.xlsx');
    });
  });
  e.cancel = true;
}; 



  const dataSourceMarca = new CustomStore({
    key: 'codigo',
    load : () => marcas(),
    byKey : (key) => {
        console.log(key);
    }
    
  });

  
  const dataSourceModelos = new CustomStore({
    key: 'codigo',
    load : () => modelos(),
    
    byKey : (key) => {
        console.log(key);
    }
    
  });

  