import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { signIn as sendSignInRequest } from '../api/auth';
import { getUser } from '../api/user-session';
import { asistencia } from '../api/crm';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [asistencias, setAsistencia] = useState();

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
        
      }

      setLoading(false);
    })();
  }, []);

    const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      const resultUsr = await getUser();
      setUser(resultUsr.data);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
  }, []);

  const getAsistencia = useCallback(async() => {
    const result =  await asistencia();
    setAsistencia(result);
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, getAsistencia,asistencias }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
