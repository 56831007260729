import React, { useState,useCallback } from 'react';
import './profile.scss';
import Form, 
{ ButtonItem }from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';


export default function Profile() {
  const { user  } = useAuth();
  const [notes, setNotes] = useState(
    'Usuario de Techno Solutions, sección con descrición del perfil de usuario.'
  );
  /*
  const employee = {
    ID: 7,
    FirstName: 'Antonio',
    LastName: 'Johnson',
    Prefix: 'Mrs.',
    Position: 'Controller',
    Picture: 'images/employees/06.png',
    BirthDate: new Date('1974/11/5'),
    HireDate: new Date('2005/05/11'),
    Notes: notes,
    Address: '4600 N Virginia Rd.'
  };*/

  const buttonOptions = {
    text: 'Register',
    type: 'success',
    useSubmitBehavior: true,
  };

  const onUpdateProfileClick = useCallback(() => {
    setNotes("Usuario Actualizado")
  }, [setNotes]);

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Profile</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <div className={'form-avatar'}>
          <img
            alt={''}
            src={"https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"}
          />
        </div>
        <span>{notes}</span>
      </div>

      <div className={'content-block dx-card responsive-paddings'}>
        <Form
          id={'form'}
          defaultFormData={user}
          onFieldDataChanged={e => e.dataField === 'Notes' && setNotes(e.value)}
          labelLocation={'top'}
          colCountByScreen={colCountByScreen}
          onSubmit={onUpdateProfileClick}
        
            />
          
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
};
