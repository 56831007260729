import {
    HomePage, 
    TasksPage, 
    ProfilePage,
    UsersPage,
    EmpleadosPage,
    AsistenciaPage, 
    ProductosPage,
    ProductoMarcaPage,
    ProductoModeloPage,
    ClientePage} 
from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/users',
        element: UsersPage
    },
    
    {
        path: '/empleados',
        element: EmpleadosPage
    },
    {
        path: '/asistencia',
        element: AsistenciaPage
    },
    {
        path: '/marcas',
        element: ProductoMarcaPage
    },
    {
        path: '/modelos',
        element: ProductoModeloPage
    },
    {
        path: '/productos',
        element: ProductosPage
    },
    {
        path: '/clientes',
        element: ClientePage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
