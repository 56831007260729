import React from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  Export,
  FilterRow
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import {getRoles} from '../../api/user-session'
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import CustomStore from 'devextreme/data/custom_store';


export default function Task() {
  
  return (
    <React.Fragment>
      
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onExporting = {onExporting}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        
        <Export enabled={true}  allowExportSelectedData={true} />
        <Column dataField={'ROL_ID'}  hidingPriority={2} />
        
        <Column
          dataField={'ROL_DESCRIPCION'}
          caption={'Descripciòn'}
          
          hidingPriority={3}
        />
        <Column
          dataField={'SYS_CREATE_AT'}
          caption={'Fecha Inicial'}
          dataType={'date'}
          hidingPriority={3}
        />
        <Column
          dataField={'SYS_UPDATE_AT'}
          caption={'Actualizo'}
          dataType={'date'}
          hidingPriority={4}
        />
        <Column
          dataField={'SYS_USER_IN'}
          caption={'Usuario'}
          name={'Usuario'}
          hidingPriority={1}
        />
        
      </DataGrid>
    </React.Fragment>
)}

const onExporting =  (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    });
  });
  e.cancel = true;
}; 

const dataSource = new CustomStore({
    key: 'ROL_ID',
    load : () => getRoles()
  });

