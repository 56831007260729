import axios from 'axios';
import { createUrl, services } from './session'

async function saveToken(token = "") { 
  window.sessionStorage.setItem("token_session",token);
}

export async function signIn(user_id, password) {
  try {
 
    var user = {
          "id" : user_id,
          "password" : password
    }

    return axios.post(createUrl(services.login),user)
           .then(async (data) => {
              saveToken(data.data.token);
              return {
                isOk: true
              }
          }).catch( err => {
              var response = err.response;
            return {
              isOk: false,
              message: "Authentication failed: " + response.data.error
            }  
        });
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}


export async function createAccount(email, password) {
  try {
    // TODO dar de alta cuenta
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // TODO implementar api
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Todo implementar api
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
