import React from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Popup,
  Paging,
  Export,
  Editing,
  RequiredRule,
  Form
  
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import {marcas, PostData, PutData} from '../../../api/crm'
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import CustomStore from 'devextreme/data/custom_store';


export default function Task() {
  
  return (
    <React.Fragment>
      
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onExporting = {onExporting}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        
        <Export enabled={true}  allowExportSelectedData={true} />
        <Editing
            mode="popup"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={false}>
              <Popup title="Marca" showTitle={true} width={400} height={525} />
              <Form>
              
                <Item dataField="codigo" Editing={false} colSpan={2}>
                  <RequiredRule />
                </Item>
                
                <Item dataField="descripcion"  colSpan={2}>
                    <RequiredRule />
                  </Item>
              
                </Form>
        </Editing>
        

        <Column
          dataField={'codigo'}
          caption={'Código'}
          hidingPriority={3}
        />
        <Column
          dataField={'descripcion'}
          caption={'Descripción'}
          hidingPriority={3}
        />



      </DataGrid>
    </React.Fragment>
)}

const onExporting =  (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'marcas.xlsx');
    });
  });
  e.cancel = true;
}; 

const insertMarcas = (values) => {
    PostData("marca",values);
};

const updateMarcas = (key,values) => {
    PutData(`marca/${key}`,values);
};

const dataSource = new CustomStore({
    key: 'marca_id',
    load : () => marcas(),
    update : updateMarcas,
    insert : insertMarcas,
  });

